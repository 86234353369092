(function () {

    function initHeaderItems() {

        //Opens and closes the mobile side panel, which is just Main Menu and Utility Menu
        //document.querySelectorAll("#open-main-nav>svg").forEach(ele => {
        //    ele.addEventListener("click", (e) => {
        //        let mainMenuElement = document.getElementById("main-nav-r");
        //        if (mainMenuElement !== null) {
        //            mainMenuElement.classList.add('open');
        //        }
        //    })
        //});

        document.querySelector(".navicon.hamburger").addEventListener("click", () => {
            let mainMenuElement = document.getElementById("main-nav-r");
            let navIconContainer = document.querySelector(".navicon.hamburger");
            if (mainMenuElement !== null) {
                toggleClass(mainMenuElement, "open");
            }

            if (navIconContainer !== null) {
                toggleClass(navIconContainer, "open");
            }
        });

        // Closes the mobile search panel
        document.querySelectorAll("#open-site-search>svg").forEach(el => {
            el.addEventListener("click", (e) => {

                let searchElement = document.querySelector("#search-container-site");
                if (searchElement !== null) {
                    toggleClass(searchElement, "open");
                }

                // we toggle the close class on the svg item
                // so, when a user clicks the search icon, we hide it and show the close icon
                // and, vice versa... when the the user clicks the close icon, we hide it and show the search icon
                // an easy way to do this is to remove the close class on all of them and then add it to the target element here
                document.querySelectorAll("#open-site-search > svg").forEach(el => el.classList.remove("close"));
                el.classList.add("close");
            })
        });


        // Validates search input if left blank
        document.querySelectorAll("#search-container-site .searchButton").forEach(el => {
            el.addEventListener("click", (e) => {
                var parent = e.currentTarget.parentElement;
                var formElement = parent.parentElement;
                let searchDiv = parent.getElementsByClassName("search-query")[0].value;
                let searchErrorMsgDiv = formElement.getElementsByClassName("searchErrMsg")[0];

                if (searchDiv) {
                    searchErrorMsgDiv.innerHTML = "";
                    searchErrorMsgDiv.classList.add("hide");
                    formElement.submit();
                } else {
                    searchErrorMsgDiv.innerHTML = "Please enter search text";
                    searchErrorMsgDiv.classList.remove("hide");
                    e.preventDefault();
                    return false;
                }
            })
        });

        // Expands and collapses mobile sub menus
        document.querySelectorAll(".mobile-toggle-nav-btn").forEach(el => el.addEventListener("click", function (e) {
            if (e.currentTarget !== null) {
                var btn = e.currentTarget;
                let closestLi = e.currentTarget.closest("LI");
                if (closestLi !== null) {
                    toggleClass(btn, "open");
                    toggleClass(closestLi, "open");
                    console.log("LI: " + closestLi.classList);
                    // update aria-expanded as well
                    if (closestLi.classList.contains("open")) {
                        btn.setAttribute("aria-expanded", true);
                        btn.setAttribute("aria-label", "Collapse Submenu")
                    } else {
                        btn.setAttribute("aria-expanded", false);
                        btn.setAttribute("aria-label", "Expand Submenu")
                    }
                    console.log("btn attribute: " + btn.getAttribute("ariaExpanded"));
                }
            }
        })
        );

        // Closes the mobile search panel
        document.querySelectorAll(".footer-links-header-container button").forEach(el => {
            el.addEventListener("click", (e) => {
                // toggle plus/minus class
                // show/hide the list of links
                toggleClass(el, "open");
                let navElement = e.target.closest("nav");
                toggleClass(navElement, "open");

            })
        });



        //need to keep IDs and class names for re-use of currently css and JS/Jquery
        function addResponisveClasses() {
            if (window.innerWidth > 770) {
                //move main menu back to desktop container
                swapMenus('main-nav-div-container', 'main-nav-r', 'main-nav')

                //move util nav - append to bottom of main nav
                swapMenus('utility-nav-container', 'main-nav-r', 'utility-nav')

                //move alerts back as child of grid-container-header (outside of header)
                //swapMenus('grid-container-header', 'site-header', 'site-alerts-container')
            }
            else {
                //move main menu back to responsive container
                swapMenus('main-nav-r', 'main-nav-div-container', 'main-nav')

                //move util nav - append to bottom of main nav
                swapMenus('main-nav-r', 'utility-nav-container', 'utility-nav')

                //move alerts back as child of HEADER
                //swapMenus('site-header', 'grid-container-header', 'site-alerts-container')


            }

        }

        //call it first time around
        addResponisveClasses();
        //then listen for resize
        window.onresize = addResponisveClasses;

        // Utility function to toggle a class... probably should move to a general utility class
        function toggleClass(element, className) {
            if (element.classList.contains(className)) {
                element.classList.remove(className);
            }
            else {
                element.classList.add(className);
            }
        }
        //the container we are appending the element to
        //the container we are removing the element from
        //the container of the element itself
        function swapMenus(containerAppendTo, containerRemoveFrom, elemenToMove) {

            //append menu to this container
            let appentToContainer = document.getElementById(containerAppendTo);
            if (typeof (appentToContainer) === "undefined" || appentToContainer === null) {
                return;
            }
            //nav
            let menunav = document.getElementById(elemenToMove);
            if (typeof (menunav) === "undefined" || menunav === null) {
                return;
            }
            //first check it's location to see if we need to move it
            let parentEle = menunav.parentNode;
            if (typeof (parentEle) === "undefined" || parentEle === null) {
                return;
            }
            //move
            if (parentEle.id === containerRemoveFrom) {
                appentToContainer.appendChild(menunav);
            }

        }



        //MAIN NAV - responsive menu
        document.querySelectorAll("#main-nav ul > li.has-children > a").forEach(ele => {
            ele.addEventListener("click", (e) => {
                standardTopMenuResponsive(e);
            })
        });
        document.querySelectorAll("#main-nav ul > li.has-children > span").forEach(ele => {
            ele.addEventListener("click", (e) => {
                standardTopMenuResponsive(e);
            })
        });
        document.querySelectorAll("#main-nav ul > li.has-children:after").forEach(ele => {
            ele.addEventListener("click", (e) => {
                standardTopMenuResponsive(e);
            })
        });


        function standardTopMenuResponsive(e) {
            //console.log("1. standardTopMenuResponsive");
            //touchscreen or desktop
            let target = e.currentTarget;
            if (window.innerWidth < 769 && target.tagName === 'A') {
                e.preventDefault();
            }

            let parent = e.currentTarget.parentElement;
            document.parent("li.has-children").forEach(ele => {
                ele.classList.remove("opened");
                ele.classList.setAttribute("aria-expanded", "false");
                ele.querySelectorAll("li.has-children").forEach(els => {
                    els.classList.remove("opened");
                    els.setAttribute("aria-expanded", "false")
                });
            });

            parent.classList.add("opened")
            parent.setAttribute("aria-expanded", "true");

        }
    }
    // wait for the page to load before we initialize the functionality
    if (document.readyState === "complete" || document.readyState === "interactive") {
        setTimeout(1, initHeaderItems);
    } else {
        document.addEventListener("DOMContentLoaded", initHeaderItems);
    }
})();
